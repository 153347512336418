import dictionary, { Culture } from "@/dictionary";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import HttpClient from "@/utils/httpClient";
import { AvailableLanguagesLocalized } from "lib/eduConfigurationServiceClient";
import { Settings } from "luxon";
import { reactive } from "vue";

const fallbackCulture = "en-US";

export interface AppContext {
  tenant: {
    uri: string;
    languages: AvailableLanguagesLocalized;
    defaultCulture: Culture;
  };
  color: string;
  pageWidth?: string;
  culture: Culture;
  texts: (typeof dictionary)["en-US"];
}
export const app = reactive<AppContext>({
  tenant: {
    uri: "",
    languages: {} as AvailableLanguagesLocalized,
    defaultCulture: fallbackCulture,
  },
  color: "#404040",
  pageWidth: undefined,
  culture: fallbackCulture,
  texts: dictionary[fallbackCulture],
});

export async function loadAppTenant(uri: string) {
  HttpClient.setTenant(uri);
  const languages = await eduConfigurationServiceClient.getPublicLanguages();
  app.tenant.uri = uri;
  app.tenant.languages = languages;
  app.tenant.defaultCulture =
    (languages.mainLanguage.locale.value as Culture) ?? fallbackCulture;

  setAppCulture(app.tenant.defaultCulture);
}

export function setAppColor(color: string) {
  app.color = color;
}

export function setAppTitle(title: string) {
  document.title = title;
}

export function setAppFavicon(favicon: string) {
  const link = document.querySelector("link[rel='icon']");
  if (link instanceof HTMLLinkElement) {
    link.href = favicon;
  } else {
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = favicon;
    document.head.appendChild(link);
  }
}

export function setAppCulture(culture: Culture) {
  HttpClient.setCulture(culture);
  Settings.defaultLocale = culture;
  app.culture = culture;
  app.texts = dictionary[culture];
}

export function setAppPageWidth(width: string) {
  app.pageWidth = width;
}
