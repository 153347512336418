<template>
  <input
    v-model="checked"
    type="checkbox"
    class="text-tenant focus:ring-tenant h-4 w-4 rounded-sm border-gray-300"
    :indeterminate="indeterminate"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

const checked = defineModel<boolean | null | string[]>({
  set(value) {
    if (checked.value == null) {
      return false;
    } else {
      return value ?? null;
    }
  },
});

const indeterminate = computed(() => checked.value === null);
</script>
