<template>
  <div class="group relative rounded-md shadow-xs">
    <div
      v-if="icon"
      :class="[
        'pointer-events-none absolute inset-y-0 left-0 flex items-center',
        {
          'pl-1': size === 'xs',
          'pl-2': size === 'sm',
          'pl-3': size === 'md',
          'pl-4': size === 'lg' || size === 'xl',
        },
      ]"
    >
      <Icon :icon :size class="group-focus-within:text-tenant text-gray-400" />
    </div>
    <input
      :id
      v-model="value"
      :name
      :type
      :class="[
        'block w-full',
        !icon
          ? {
              'px-1 py-0.5': size === 'xs',
              'px-2 py-1': size === 'sm',
              'px-3 py-1.5': size === 'md',
              'px-4 py-2': size === 'lg',
              'px-4 py-2.5': size === 'xl',
            }
          : {
              'py-1 pr-1 pl-3': size === 'xs',
              'py-1.5 pr-2 pl-6': size === 'sm',
              'py-1.5 pr-3 pl-9': size === 'md',
              'py-2 pr-4 pl-12': size === 'lg',
              'py-2.5 pr-4 pl-16': size === 'xl',
            },

        {
          'text-xs font-normal': size === 'xs',
          'text-sm font-normal': size === 'sm',
          'text-base font-normal': size === 'md',
          'text-lg font-normal': size === 'lg',
          'text-xl font-normal': size === 'xl',
        },
        'text-gray-900 placeholder:text-gray-400',
        'rounded-md border-0',
        'focus:ring-tenant ring-1 ring-gray-200 outline-hidden ring-inset focus:ring-2 focus:ring-inset',
      ]"
      :placeholder
    />
  </div>
</template>

<script setup lang="ts">
import { Size } from "@/elements/enums";
import { FunctionalComponent } from "vue";

type InputType =
  | "text"
  | "search"
  | "email"
  | "password"
  | "number"
  | "tel"
  | "url";
withDefaults(
  defineProps<{
    id?: string;
    name?: string;
    type?: InputType;
    size?: Size;
    placeholder?: string;
    icon?: FunctionalComponent;
  }>(),
  {
    id: undefined,
    name: undefined,
    type: "text",
    size: "md",
    placeholder: undefined,
    icon: undefined,
  },
);

const value = defineModel<string | number>();
</script>
