<template>
  <select
    v-model="value"
    class="inline-flex items-center justify-start gap-1.5 rounded-md border border-gray-200 bg-white text-gray-800 shadow-xs"
    :class="[
      {
        'py-1 pr-8 pl-3': size === 'sm',
        'py-1.5 pr-9 pl-3': size === 'md',
        'py-2 pr-10 pl-3': size === 'lg',
      },
      {
        'text-sm font-normal': size === 'sm',
        'text-base font-normal': size === 'md',
        'text-lg font-normal': size === 'lg',
      },
      'border-0',
      'focus:ring-tenant ring-1 ring-gray-200 outline-hidden ring-inset focus:ring-2 focus:ring-inset',
    ]"
  >
    <slot></slot>
  </select>
</template>

<script setup lang="ts">
defineProps<{
  size?: string;
}>();

const value = defineModel<string>();
</script>
